@import 'src/assets/teacher/stylesheets/teachers/global_colors';

// $dark-grey : var(--dark-grey);
// $dark-grey-2 : var(--dark-grey-2);
// $medium-grey-1 : var(--medium-grey-1);
// $medium-grey-2 : var(--medium-grey-2);
// $light-grey-1 : var(--light-grey-1);
// $light-gray-1 : var(--light-gray-1);
// $light-grey-2 : var(--light-grey-2);
// $light-gray-2 : var(--light-gray-2);
// $light-gray-3 : var(--light-gray-3);
// $light-grey-3 : var(--light-grey-3);
// $light-grey-4 : var(--light-grey-4);
// $light-grey-5 : var(--light-grey-5);
// $white : var(--white);
// $dark-navy-blue : var(--dark-navy-blue);
// $navy-blue : var(--navy-blue);
// $gray-navy-blue : var(--gray-navy-blue);
// $gray-navy-blue-2 : var(--gray-navy-blue-2);
// $pure-blue : var(--pure-blue);
// $light-blue-1 : var(--light-blue-1);
// $light-blue-2 : var(--light-blue-2);
// $light-blue-3 : var(--light-blue-3);
// $light-blue-4 : var(--light-blue-4);
// $pure-purple : var(--pure-purple);
// $dark-purple : var(--dark-purple);
// $medium-purple : var(--medium-purple);
// $dull-purple : var(--dull-purple);
// $purple : var(--purple);
// $light-purple-1 : var(--light-purple-1);
// $light-purple-2 : var(--light-purple-2);
// $light-purple-3 : var(--light-purple-3);
// $magenta-3 : var(--magenta-3);
// $magenta-4 : var(--magenta-4);
// $magenta-8 : var(--magenta-8);
// $mint-green : var(--mint-green);
// $dark-green : var(--dark-green);
// $pure-green : var(--pure-green);
// $light-green : var(--light-green);
// $light-green-1 : var(--light-green-1);
// $light-green-2 : var(--light-green-2);
// $dark-green-1 : var(--dark-green-1);
// $dark-yellow : var(--dark-yellow);
// $pure-yellow : var(--pure-yellow);
// $light-yellow-1 : var(--light-yellow-1);
// $light-yellow-2 : var(--light-yellow-2);
// $light-yellow-3 : var(--light-yellow-3);
// $dark-orange : var(--dark-orange);
// $pure-orange : var(--pure-orange);
// $light-orange : var(--light-orange);
// $dark-red : var(--dark-red);
// $pure-red : var(--pure-red);
// $light-red : var(--light-red);
// $pure-black : var(--pure-black);

$dark-grey : #1A1A1A;
$dark-grey-2 : #575757;
$medium-grey-1 : #6C6C73;
$medium-grey-2 : #8F8F9B;
$light-grey-1 : #CACAD8;
$light-gray-1 : #D6D6D6;
$light-grey-2 : #DCDCEA;
$light-gray-2 : #EDEDED;
$light-gray-3 : #EEEFF3;
$light-grey-3 : #EDEDF9;
$light-grey-4 : #F8F8FD;
$light-grey-5 : #E7E7F2;
$white : #FFFFFF;
$dark-navy-blue : #090A4A;
$navy-blue : #141975;
$navy-blue-1 : #34389F;
$gray-navy-blue : #2B2C63;
$gray-navy-blue-2 : #494A88;
$pure-blue : #2284E2;
$light-blue-1 : #A7CEF3;
$light-blue-2 : #D3E6F9;
$light-blue-3 : #EDF5FD;
$light-blue-4 : #EAF4FF;
$pure-purple : #4F52C3;
$dark-purple : #38397B;
$medium-purple : #797CDB;
$dull-purple : #989BEC;
$purple : #E6E7FD;
$light-purple-1 : #BDBFF9;
$light-purple-2 : #DEDFFC;
$light-purple-3 : #E6E7FD;
$magenta-3 : #8535A9;
$magenta-4 : #B746E6;
$magenta-8 : #F1DAFA;
$mint-green : #537D74;
$dark-green : #4CAB78;
$pure-green : #69D59B;
$light-green : #F3FCF7;
$light-green-1 : #83C034;
$light-green-2 : #68a526;
$dark-green-1 : #537d74;
$dark-green-2: #2F8973;
$dark-yellow : #D7A22B;
$pure-yellow : #FFC543;
$light-yellow-1 : #FFE8B4;
$light-yellow-2 : #FFF3D9;
$light-yellow-3 : #FFFAF0;
$dark-orange : #C55314;
$pure-orange : #EE7532;
$pure-orange-1: #FD9B7D;
$pure-orange-2: #F9805D;
$light-orange : #FFF5F2;
$dark-red : #D23446;
$pure-red : #F1465A;
$light-red : #FDF1F1;
$dark-red-2 : #B84040;
$pure-black : #000000;
$hover-purple: #9d9ffc1a;
$faded-blue: #D3E6F9;
$primary-blue: #2284E2;
$faded-purple: #e5e5fd;
$full-purple: #5052c3;
$content-text-secondary : #484848;
$content-text-tertiary : #767676;
$white-1: #FAFAFF;
$white-2: #F7F7FA;
$success-green: #DCFAE9;
$text-field-border-default: #A2A2AD;
$content-notice-error: #901818;
$content-notice-alert: #533B03;