@import '@baseStyles/styles.scss';

.teacher-and-parent-login {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;

    &.teacher {
        .teacher-social {
            display: flex;
        }
        .parent-social {
            display: none;
        }
    }

    &.parent {
        .teacher-social {
            display: none;
        }
        .parent-social {
            display: flex;
        }
    }

    &>h4 {
        @include font-graphie(28, 36, 7);
        color: #464A4D;
        text-align: center;
        margin-bottom: 22px;
        margin-top: 77px;
    }

    .tab-view {
        display: flex;
        background: #FFFFFF;
        border-radius: 4px;
        overflow: hidden;
        min-height: 41px;
        box-sizing: border-box;
        margin-bottom: 24px;

        .tab {
            padding: 9px 59px;
            box-sizing: border-box;
            text-align: center;
            @include font-graphie(16, 18, 4);
            color: #6C6C73;
            cursor: pointer;
            border-width: 1px 1px 4px 1px;
            border-style: solid;
            border-bottom: 4px solid #E7E7F2;
            border-color: #E7E7F2;
            transition: all 0.5s;

            &:hover {
                cursor: pointer;
            }

            &.active {
                border-bottom: 4px solid #4F52C3;
                background: #E6E7FD;
                color: #090A4A;
                @include font-graphie(16, 18, 6);
            }
        }
    }

    .register-link {
        @include font-graphie(14, 17, 4);
        color: #1A1A1A;
        padding: 10px;
        margin-top: 24px;
        text-align: center;

        a {
            text-decoration: underline;
            color: #4F52C3;

            &:hover {
                color: #141975;
                cursor: pointer;
            }
        }
    }

    @media screen and (min-height: 700px) {
        justify-content: center;

        &>h4 {
            margin-top: 0px;
        }
    }
}