/* -------------------------------------------------------------
  Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
  By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets

  List of CSS3 Sass Mixins File to be @imported and @included as you need

  The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
  ENDING css files

  note: All CSS3 Properties are being supported by Safari 5
  more info: http://www.findmebyip.com/litmus/#css3-properties

  Mixins available:
    -   background-gradient     - arguments: Start Color: #3C3C3C, End Color: #999999
    -   background-size         - arguments: Width: 100%, Height: 100%
    -   border-radius           - arguments: Radius: 5px
    -   border-radius-separate  - arguments: Top Left: 5px, Top Left: 5px, Bottom Left: 5px, Bottom Right: 5px
    -   box                     - arguments: Orientation: horizontal, Pack: center, Align: center
    -   box-rgba                - arguments: R: 60, G: 3, B: 12, Opacity: 0.23, Color: #3C3C3C
    -   box-shadow              - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
    -   box-sizing              - arguments: Type: border-box
    -   columns                 - arguments: Count: 3, Gap: 10
    -   double-borders          - arguments: Color One: #3C3C3C, Color Two: #999999, Radius: 0
    -   flex                    - arguments: Value: 1
    -   flip                    - arguments: ScaleX: -1
    -   font-face               - arguments: Font Family: myFont, Eot File Src: myFont.eot, Woff File Src: myFont.woff, Ttf File Src: myFont.ttf
    -   opacity                 - arguments: Opacity: 0.5
    -   outline radius          - arguments: Radius: 5px
    -   resize                  - arguments: Direction: both
    -   rotate                  - arguments: Degree: 0, M11: 0, M12: 0, M21: 0, M22: 0
    CSS Matrix Rotation Calculator http://www.boogdesign.com/examples/transforms/matrix-calculator.html
    -   text-shadow             - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
    -   transform               - arguments: Parameters: null
    -   transition              - arguments: What: all, Length: 1s, Easing: ease-in-out
    -   triple-borders          - arguments: Color One: #3C3C3C, Color Two: #999999, Color Three: #000000, Radius: 0

------------------------------------------------------------- */

@function image-url($path){
@return url('TEACHER_ASSETS/images/' + $path);
}

/* BACKGROUND GRADIENT */


@mixin background-gradient($startColor: #3C3C3C, $endColor: #999999) {
  background-color: $startColor;
  background-image: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
  background-image: -webkit-linear-gradient(top, $startColor, $endColor);
  background-image: -moz-linear-gradient(top, $startColor, $endColor);
  background-image: -ms-linear-gradient(top, $startColor, $endColor);
  background-image: -o-linear-gradient(top, $startColor, $endColor);
  background-image: linear-gradient(top, $startColor, $endColor);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr=$startColor, EndColorStr=$endColor);
}

@mixin gradient-vertical($startColor: #555, $endColor: #333) {
  background-color: mix($startColor, $endColor, 60%);
  background-image: -moz-linear-gradient(top, $startColor, $endColor); // FF 3.6+
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($startColor), to($endColor)); // Safari 4+, Chrome 2+
  background-image: -webkit-linear-gradient(top, $startColor, $endColor); // Safari 5.1+, Chrome 10+
  background-image: -o-linear-gradient(top, $startColor, $endColor); // Opera 11.10
  background-image: linear-gradient(to bottom, $startColor, $endColor); // Standard, IE10
  background-repeat: repeat-x;
}

@mixin gradient-striped($color: #555, $angle: 45deg) {
  background-color: $color;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, rgba(255, 255, 255, .15)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(255, 255, 255, .15)), color-stop(.75, rgba(255, 255, 255, .15)), color-stop(.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient($angle, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient($angle, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient($angle, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient($angle, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}

/* BACKGROUND SIZE */

@mixin background-size($value...) {
  -moz-background-size: $value;
  -webkit-background-size: $value;
  background-size: $value;
}

@mixin transform($value...) {
  transform: $value;
  /* Firefox 16+, IE 10+, Opera 12.10+  */
  -webkit-transform: $value;
  /* Chrome, Safari 3.1+ */
  -moz-transform: $value;
  /* Firefox 3.5-15  */
  -o-transform: $value;
  /* Opera 10.50-12.00  */
  -ms-transform: $value;
  /* IE 9  */
}

@mixin transform-origin($value...) {
  -webkit-transform-origin: $value;
  /* Chrome, Safari 3.1+ */
  -moz-transform-origin: $value;
  /* Firefox 3.5-15  */
  -o-transform-origin: $value;
  /* Opera 10.50-12.00  */
  -ms-transform-origin: $value;
  /* IE 9  */
  transform-origin: $value;
  /* Firefox 16+, IE 10+, Opera 12.10+  */
}

@mixin transition-prefix($property, $duration, $timing-function : linear, $delay : 0s) {
  -webkit-transition: #{"-webkit-" + $property} $duration $timing-function $delay;
  -moz-transition: #{"-moz-" + $property} $duration $timing-function $delay;
  -o-transition: #{"-o-" + $property} $duration $timing-function $delay;
  transition: $property $duration $timing-function $delay;
}

/* SCALE */
@mixin scale($x, $y: $x, $z: 1) {
  -moz-transform: scale3d($x, $y, $z);
  -o-transform: scale3d($x, $y, $z);
  -webkit-transform: scale3d($x, $y, $z);
  -ms-transform: scale($x, $y);
  transform: scale3d($x, $y, $z);
}

@mixin transform-scale($x, $y: $x) {
  -moz-transform: scale($x, $y);
  -o-transform: scale($x, $y);
  -webkit-transform: scale($x, $y);
  -ms-transform: scale($x, $y);
  transform: scale($x, $y);
}


/* BORDER RADIUS */
@mixin border-radius($radius...) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

@mixin border-radius-separate($topLeftRadius: 5px, $topRightRadius: 5px, $bottomLeftRadius: 5px, $bottomRightRadius: 5px) {
  -webkit-border-top-left-radius: $topLeftRadius;
  -webkit-border-top-right-radius: $topRightRadius;
  -webkit-border-bottom-right-radius: $bottomLeftRadius;
  -webkit-border-bottom-left-radius: $bottomRightRadius;

  -moz-border-radius-topleft: $topLeftRadius;
  -moz-border-radius-topright: $topRightRadius;
  -moz-border-radius-bottomright: $bottomLeftRadius;
  -moz-border-radius-bottomleft: $bottomRightRadius;

  border-top-left-radius: $topLeftRadius;
  border-top-right-radius: $topRightRadius;
  border-bottom-right-radius: $bottomLeftRadius;
  border-bottom-left-radius: $bottomRightRadius;
}

/* BOX */
@mixin box($orient: horizontal, $pack: center, $align: center) {
  display: -webkit-box;
  display: -moz-box;
  //TODO important
  //TODO check whats happening here

  //display: box;

  -webkit-box-orient: $orient;
  -moz-box-orient: $orient;
  box-orient: $orient;

  -webkit-box-pack: $pack;
  -moz-box-pack: $pack;
  box-pack: $pack;

  -webkit-box-align: $align;
  -moz-box-align: $align;
  box-align: $align;
}

/* BOX RGBA */
@mixin box-rgba($r: 60, $g: 3, $b: 12, $opacity: 0.23, $color: #3C3C3C) {
  background-color: transparent;
  //TODO important
  //TODO check whats happening here
  //background-color: rgba($r, $g, $b, $opacity);
  //          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr#$color, endColorstr=$color);
  //          zoom:   1;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-ms-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}


/* BOX SHADOW */
@mixin box-shadow($value...) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}

/* BOX SIZING */
@mixin box-sizing($type: border-box) {
  -webkit-box-sizing: $type;
  -moz-box-sizing: $type;
  box-sizing: $type;
}

/* COLUMNS */
@mixin columns($count: 3, $gap: 10) {
  -webkit-column-count: $count;
  -moz-column-count: $count;
  column-count: $count;

  -webkit-column-gap: $gap;
  -moz-column-gap: $gap;
  column-gap: $gap;
}

/* DOUBLE BORDERS */
@mixin double-borders($colorOne: #3C3C3C, $colorTwo: #999999, $radius: 0) {
  border: 1px solid $colorOne;

  -webkit-box-shadow: 0 0 0 1px $colorTwo;
  -moz-box-shadow: 0 0 0 1px $colorTwo;
  box-shadow: 0 0 0 1px $colorTwo;

  @include border-radius($radius );
}

/* FLEX */
@mixin flex($value: 1) {
  -webkit-box-flex: $value;
  -moz-box-flex: $value;
  box-flex: $value;
}

/* FLIP */
@mixin flip($scaleX: -1) {
  -moz-transform: scaleX($scaleX);
  -o-transform: scaleX($scaleX);
  -webkit-transform: scaleX($scaleX);
  transform: scaleX($scaleX);
  //TODO important
  //TODO check whats happening here
  filter: "FlipH";
  -ms-filter: "FlipH";
}

/* FONT FACE */
@mixin font-face($fontFamily: myFont, $eotFileSrc: 'myFont.eot', $woffFileSrc: 'myFont.woff', $ttfFileSrc: 'myFont.ttf') {
  font-family: $fontFamily;
  src: url($eotFileSrc) format('eot'),
    url($woffFileSrc) format('woff'),
    url($ttfFileSrc) format('truetype');
}

/* OPACITY */
@mixin opacity($opacity: 0.5) {
  filter: alpha(opacity=($opacity * 100));
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + ($opacity * 100) + ")";
  -moz-opacity: $opacity;
  -khtml-opacity: $opacity;
  opacity: $opacity;
}


/* OUTLINE RADIUS */
@mixin outline-radius($radius: 5px) {
  -webkit-outline-radius: $radius;
  -moz-outline-radius: $radius;
  outline-radius: $radius;
}

/* RESIZE */
@mixin resize($direction: both) {
  -webkit-resize: $direction;
  -moz-resize: $direction;
  resize: $direction;
}

/* ROTATE*/
@mixin rotate($deg: 0, $m11: 0, $m12: 0, $m21: 0, $m22: 0) {
  -moz-transform: rotate($deg + deg);
  -o-transform: rotate($deg + deg);
  -webkit-transform: rotate($deg + deg);
  -ms-transform: rotate($deg + deg);
  transform: rotate($deg + deg);
  // filter: progid:DXImageTransform.Microsoft.Matrix(
  //         M11=$m11, M12=$m12,M21=$m21, M22=$m22, sizingMethod='auto expand');
  zoom: 1;
}

/* TRANSLATE */
@mixin translate($x, $y, $z: 0px) {
  -moz-transform: translate3d($x, $y, $z);
  -o-transform: translate3d($x, $y, $z);
  -webkit-transform: translate3d($x, $y, $z);
  -ms-transform: translate($x, $y);
  transform: translate3d($x, $y, $z);
}

/* TEXT SHADOW */
@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0, 0, 0, .4)) {
  text-shadow: $x $y $blur $color;
}

/* ANIMAITON  */
@mixin animation-duration($time: 1s) {
  -webkit-animation-duration: $time;
  -moz-animation-duration: $time;
  -ms-animation-duration: $time;
  -o-animation-duration: $time;
  animation-duration: $time;
}

@mixin animation-timing-function($timing-function) {
  -webkit-animation-timing-function: $timing-function;
  -moz-animation-timing-function: $timing-function;
  -ms-animation-timing-function: $timing-function;
  -o-animation-timing-function: $timing-function;
  animation-timing-function: $timing-function;
}

@mixin animation-name($value) {
  -webkit-animation-name: $value;
  -moz-animation-name: $value;
  -ms-animation-name: $value;
  -o-animation-name: $value;
  animation-name: $value;
}

@mixin animation-iteration-count($value: infinite) {
  -webkit-animation-iteration-count: $value;
  -moz-animation-iteration-count: $value;
  -ms-animation-iteration-count: $value;
  -o-animation-iteration-count: $value;
  animation-iteration-count: $value;
}

@mixin animation-play-state($value) {
  -webkit-animation-play-state: $value;
  -moz-animation-play-state: $value;
  -ms-animation-play-state: $value;
  -o-animation-play-state: $value;
  animation-play-state: $value;
}

@mixin animation($value...) {
  -webkit-animation: $value;
  -moz-animation: $value;
  -ms-animation: $value;
  -o-animation: $value;
  animation: $value;
}


/* TRANSITION */
@mixin transition($value...) {
  -moz-transition: $value;
  -o-transition: $value;
  -webkit-transition: $value;
  -ms-transition: $value;
  transition: $value;
}


@mixin transition-duration($length: 1s) {
  -moz-transition-duration: $length;
  -o-transition-duration: $length;
  -webkit-transition-duration: $length;
  -ms-transition-duration: $length;
  transition-duration: $length;
}


/* TRIPLE BORDERS */
@mixin triple-borders($colorOne: #3C3C3C, $colorTwo: #999999, $colorThree: #000000, $radius: 0) {
  border: 1px solid $colorOne;

  @include border-radius($radius);

  -webkit-box-shadow: 0 0 0 1px $colorTwo, 0 0 0 2px $colorThree;
  -moz-box-shadow: 0 0 0 1px $colorTwo, 0 0 0 2px $colorThree;
  box-shadow: 0 0 0 1px $colorTwo, 0 0 0 2px $colorThree;
}

@mixin screen-size($media) {
  @if $media ==phone {
    @media only screen and (max-width: 767px) {
      @content;
    }
  }

  @else if $media ==iphone5 {
    @media only screen and (min-width: 320px) and (max-width: 568px) {
      @content;
    }
  }

  @else if $media ==iphone4 {
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      @content;
    }
  }
}

@mixin element($name) {
  &__#{$name} {
    @content;
  }
}

@mixin modifier($name) {
  &--#{$name} {
    @content;
  }
}

@mixin button-theme-flat($bg: #3594BC, $hover-bg: #0E658A, $radius: 4px) {
  background: $bg;
  @include border-radius($radius);
  text-transform: none;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background: $hover-bg;
    border: none;
    margin-top: 0;
  }
}

//Mixins for converting px to rem

//Mixins for changing svg image color
@mixin svg-style($color) {
  path {
    fill: $color;
  }

  rect {
    fill: $color;
  }
}

@mixin custom-scrollbar {
  overflow: auto;
  overflow: overlay;
  &::-webkit-scrollbar {
    display: none;
  }

    &:hover {
      &::-webkit-scrollbar {
          width: 8px;
          display: block;
      }
      &::-webkit-scrollbar-track {
          background-color: transparent;    
      }
      &::-webkit-scrollbar-thumb {
          background: rgba(79, 82, 195, 0.25);
          border: 1px solid rgba(9, 10, 74, 0.2);
          border-radius: 10px;
      }
    }
}

@mixin custom-scrollbar-transparent {
  overflow: auto;
  overflow: overlay;
  &::-webkit-scrollbar {
    display: none;
  }

    &:hover {
      &::-webkit-scrollbar {
          width: 0;
          display: block;
      }
      &::-webkit-scrollbar-track {
          background-color: transparent;    
      }
      &::-webkit-scrollbar-thumb {
          background: rgba(79, 82, 195, 0);
          border: 0 solid rgba(9, 10, 74, 0);
          border-radius: 0;
      }
    }
}

@mixin custom-scrollbar-without-hover {
  overflow: auto;
  overflow: overlay;

  &::-webkit-scrollbar {
    width: 8px;
    display: block;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(79, 82, 195, 0.25);
    border: 1px solid rgba(9, 10, 74, 0.2);
    border-radius: 10px;
  }

}

@mixin custom-scrollbar-without-hover-thin {
  overflow: auto;
  overflow: overlay;

  &::-webkit-scrollbar {
    width: 8px !important;
    display: block;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    width: 4px !important;

  }

  &::-webkit-scrollbar-thumb {
    background: transparent !important;
    border: 1px solid transparent !important;
    border-radius: 10px;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.3) !important;
      border: 0px solid rgba(255, 255, 255, 0.3) !important;
      border-radius: 10px;
    }
  }
}

@mixin transparent-custom-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}