@import '@baseStyles/styles.scss';

.username-login {
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;

    &>h4 {
        @include font-graphie(28, 36, 7);
        color: #464A4D;
        text-align: center;
        margin-bottom: 22px;
        margin-top: 77px;
    }

    @media screen and (min-height: 700px) {
        justify-content: center;

        &>h4 {
            margin-top: 0px;
        }
    }
}