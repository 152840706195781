@import '@baseStyles/styles.scss';

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .login-box {
        background: #F8F8FD;
        border-radius: 8px;
        padding: 20px;
        box-sizing: border-box;
        width: 354px;

        .form-field {
            margin-top: 20px;

            &:first-child {
                margin-top: 0;
            }

            label {
                @include font-graphie(14, 17, 4);
                margin-bottom: 4px;
                color: #21242B;
                display: flex;
                justify-content: space-between;

                a {
                    text-decoration: underline;
                    color: #4F52C3;

                    &:hover {
                        color: #141975;
                        cursor: pointer;
                    }

                    &.disabled {
                        pointer-events: none;
                        color: #6C6C73;
                    }

                    &.otp-timer {
                        text-decoration: none;
                    }
                }
            }

            input {
                background: #FFFFFF;
                border: 1px solid #CACAD8;
                border-radius: 8px;
                color: #1A1A1A;
                @include font-graphie(14, 18, 4);
                padding: 9px 12px;
                box-sizing: border-box;
                width: 314px;

                &::placeholder {
                    color: #8F8F9B;
                }

                &:disabled {
                    background: #E7E7F2;
                    cursor: not-allowed;
                }
            }

            .otp-text {
                box-sizing: border-box;
                @include font-graphie(28, 34, 6);
                text-transform: uppercase;
                position: relative;
                display: flex;
                margin-top: 8px;

                .otp {
                    position: absolute;
                    @include font-graphie(28, 34, 6);
                    letter-spacing: 35px;
                    text-transform: uppercase;
                    border: 0;
                    background: transparent;
                    padding: 0;
                    color: transparent;
                    caret-color: #8F8F9B;
                    padding-left: 12px;
                }

                div {
                    background: transparent;
                    height: 40px;
                    width: 44px;
                    display: block;
                    margin-right: 10px;
                    border-bottom: 1px solid #8F8F9B;
                    text-align: center;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .autocomplete {
                    width: 0;
                    height: 0;
                    padding: 0;
                    opacity: 0;
                }

            }

            .info {
                @include font-graphie(14, 20, 4);
                color: #1A1A1A;
                margin-top: 8px;
                display: flex;
                align-items: center;

                i {
                    margin-right: 7px;
                }

                &.error {
                    color: #B84040;
                }

                &.success {
                    color: #4CAB78;
                }
            }
        }

        &>button {
            @include font-graphie(16, 24, 6);
            color: #FFFFFF;
            background: #090A4A;
            border-radius: 40px;
            margin-top: 24px;
            width: 100%;
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            outline: 0;
            height: 44px;

            &:hover {
                background: #141975;
                cursor: pointer;
            }
        }

        .email-info {
            @include font-graphie(14, 20, 4);
            color: #1A1A1A;
            margin-top: 8px;
            display: flex;
            align-items: center;
        }
    }


    .other-login-box {
        background: #F8F8FD;
        border-radius: 8px;
        padding: 12px 0;
        @include font-graphie(14, 17, 4);
        color: #1A1A1A;
        margin-top: 16px;
        text-align: center;
        width: 354px;
        cursor: pointer;

        a {
            text-decoration: underline;
            color: #4F52C3;

            &:hover {
                color: #141975;
                cursor: pointer;
            }
        }
    }

    .or-bar {
        width: 354px;
        margin-top: 40px;
        margin-bottom: 40px;
        .or-bar-text {
            width: 100px;
            transform: translate(-50%, -50%);
            margin: 0;
            text-transform: none;
        }
    }

    #social-login-wrapper {
        .social-signups {
            left: 0;
            margin: 0;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            .new-social-signin-button {
                padding: 3px;
                margin-bottom: 0;
                width: 150px;
                margin: 5px;
                span {
                    display: flex;
                    align-items: center;
                    img {
                        width: 36px;
                        height: 36px;
                        margin: 0;
                    }
                }

                &.apple { 
                    width: 100%;
                }
            }
        }
    }
}

.login-form-v2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .otp-text {
        box-sizing: border-box;
        @include font-graphie(28, 34, 6);
        text-transform: uppercase;
        position: relative;
        display: flex;
        margin-top: 8px;

        .otp {
            position: absolute;
            @include font-graphie(28, 34, 6);
            letter-spacing: 56px;
            text-transform: uppercase;
            border: 0;
            background: transparent;
            padding: 16px 36px;
            color: transparent;
            caret-color: #8F8F9B;
            width: 100%;
            box-sizing: border-box;
        }

        div {
            width: 64px;
            height: 64px;
            background: transparent;
            display: block;
            margin-right: 8px;
            border-radius: 28px;
            border: 1px solid #8F8F9B;
            text-align: center;
            padding: 16px 0;
            box-sizing: border-box;

            &:last-child {
                margin-right: 0;
            }
        }

        @media (max-width: 1279px) {
            .otp{
                @include font-graphie(16, 24, 6);
                padding: 16px 32px;
                letter-spacing: 56px;
            }

            div {
                @include font-graphie(16, 24, 6);
                width: 60px;
                height: 56px;
            }
        }

        @media (max-width: 1023px) {
            .otp{
                @include font-graphie(16, 24, 6);
                padding: 16px 26px;
                letter-spacing: 46px;
            }

            div {
                @include font-graphie(16, 24, 6);
                width: 48px;
                height: 56px;
            }
        }

        .autocomplete {
            width: 0;
            height: 0;
            padding: 0;
            opacity: 0;
        }

    }
}