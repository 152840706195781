@import '@baseStyles/styles.scss';

.printable-worksheets{
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    padding: 32px;
    max-width: 1298px;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 20px;
    position: relative;

    &.w-env-TEACHER_DASHBOARD {
        max-width: initial;
    }

    &__left {
        height: inherit;
        overflow-y: auto;
        overflow-y: overlay;
        width: 333px;
        position: relative;

        &.un-focus{
            opacity: 0.4;
        }
    }

    &__right {
        height: inherit;
        overflow-y: auto;
        overflow-y: overlay;
        overflow-x: hidden;
        margin-left: 28px;
        width: calc(100% - 371px);
        padding-right: 18px;
        @media screen and (min-width: $mobile-width) {
            &.student-env {
                width: calc(100% - 360px);
                padding-right: 0px;
                @media screen and (max-width: $tablet-width) {
                    margin-left: 24px;
                    width: calc(100% - 356px);
                }
            }
        }

        &.un-focus{
            opacity: 0.4;
        }
    }

    &__top {
        box-sizing: border-box;
        border-bottom: 1px solid #E7E7F2;
        margin-bottom: 20px;
    }

    &__bottom {
        display: flex;
        justify-content: flex-start;
        overflow-y: auto;

        .printable-worksheets__left {
            height: inherit;
            overflow-y: auto;
            overflow-y: overlay;
            width: 333px;
            position: relative;
        }

        .printable-worksheets__right {
            height: inherit;
            overflow-y: auto;
            overflow-y: overlay;
            overflow-x: hidden;
            margin-left: 28px;
            width: calc(100% - 351px);
            @include custom-scrollbar;
            padding-right: 8px;
        }
    }

    .ws-loader-container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.printable-worksheets.w-env-STUDENT_DASHBOARD.device-tablet {
    padding: 32px 18px;
}

.printable-worksheets.w-env-TEACHER_DASHBOARD {
    padding: 24px;
    background-color: white;
    margin-bottom: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;

    .printable-worksheets__left {
        width: 225px;
        margin-right: 0;
    }
    .printable-worksheets__right {
        width: calc(100% - 233px);
        margin-left: 0;
    }

    .all-worksheets, .saved-worksheets, .downloaded-worksheets {

        .all-worksheets__list.strict-view{
            // column-gap: 17px;
        }

        &__header{
            background-color: $white;
            .go-back, .title{
                color: #34389F;
            }
        }

        &__list {
            >div {
                margin-right: 16px;
                margin-bottom: 16px;
            }

            &:last-child {
                margin-right: 0px;
            }
        }
    }
    .all-worksheets {
        &__list {
            >div {
                margin-right: 10px;
            }

            &:last-child {
                margin-right: 0px;
            }
        }
    }

    .worksheets-view__header {
        background-color: white;
        .topic {
            color: #34389F
        }
        .ws-new-header__active-topic .breadcrumbs__item {
            text-decoration: underline;
            color: $medium-grey-1;

            &:last-child {
                text-decoration: none;
                color: $dark-grey;

            }
        }
    }
    .worksheets-view__header .ws-new-header__active-topic { 
        height: auto;
    }
    .worksheets-view__list .ws-rails-container .ws-rail__header {
        margin: 0 20px 16px 0;
    }

    .worksheets-view__list .ws-rails-container .all-ws {
        &__title {
            font-weight: 700;
            color: $dark-grey;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 16px;
        }

        &__list {
            padding: 0px;
        }
    }
    .worksheets-view__header .topic {
        color: $dark-grey;
        margin-top: 14px;
        line-height: 20px;
        font-size: 18px;
        font-weight: 700;
    }
    .topic-selector .worksheet-topic-container .selected {
        color: #34389F;
        background-color: white;

        .opened-caret {
            display: block;
        }
        &:hover {
            .opened-caret {
                background: #F8F8FD;
            }
        }
    }
    .topic-selector .worksheet-topic-container {
        color: $medium-grey-1;
        min-height: 40px;
        padding-right: 8px;

        .marker {
            background-color: $light-grey-3;
        }
        .worksheet-topic-name.worksheet-topic-name.worksheet-topic-name {
            min-height: 48px;
            padding: 0;

            .opened-caret.highlighted-caret {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &:hover {
                background-color: $light-grey-4;
            }
            svg {
                @include svg-style($medium-grey-1)
            }
            .opened-caret {
                background:white;
                .highlighted-caret {
                    svg {
                        @include svg-style($medium-grey-1)
                    }
                }
            }
        }
    }
    .root-topic-container{
        min-height: 48px;
        padding-left: 0 !important;
        border-radius: 8px 8px 0px 0px;
       
        border-bottom: 1px solid #E7E7F2;
        &:last-child {
                border-bottom: 0px solid #E7E7F2;
            }
        
            &:nth-child(2) {
                margin-top: 26px;
            }
        .worksheet-topic-name.worksheet-topic-name.worksheet-topic-name {
            padding-left: 8px;
            padding-right: 12px;

            &:hover {
                background-color: $light-grey-4;
                color: $pure-purple;
                .marker {
                    background-color: $pure-purple;
                }
            }
            .worksheet-label {
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
            }
        }
        .worksheet-topic-name.selected {
            .worksheet-label {
                font-weight: 600;
            }
            .marker {
                background-color: $pure-purple;
            }
        }
    }
    .worksheets-view__list .ws-rails-container .ws-rail__header .title {
        color: $dark-grey;
        line-height: 20px;
        font-weight: 700;
    }
    .worksheets-view__list .ws-rails-container .ws-rail__header .view-printables {
        color:  #34389F;
        border-bottom: 2px solid #7678DF;
    }
    .worksheets-view__list .ws-rails-container .ws-rail__list {
        >div {
            margin-right: 11px;
        }

        &:last-child {
            margin-right: 0px;
        }
    }
    .worksheets-view__header .ws-new-header__searchbox .search-container #input-search {
        color: #1A1C6C;
        border: 1px solid  #1A1C6C;
        background-color: #FFFFFF;
        caret-color: #FFFFFF;
    }
    .worksheets-view__header .ws-new-header__searchbox .search-container {
        #input-search {
            caret-color: #7678DF;
        }
        svg {
            @include svg-style(#1A1C6C)
        }
    }
    .worksheets-view__list .ws-rails-container .ws-rail__list {
        background: transparent;
        height: 334px;
        padding: 0;
    }
    .worksheets-nav {
        color: #34389F;
        padding: 0;
        background: white;
        width: 220px;
        border-radius: 0;
        .worksheets-nav__grade-selector {
            color: #1a1c6c;
            padding-top: 0;
            .grades-container {
                color: #34389F;
                border-radius: 16px;
                .grade{
                    border-color: #34389F;
                    color: #34389F;
                    border-radius: 16px;
                    padding: 16px;
                }
                .selected{
                    border-color: #34389F;
                    color: #EDEDF9;
                    border-radius: 16px;
                    padding: 16px;
                }
            }
        }
        .topic-selector {
            color: #34389F;
            background-color: white;
            width: 210px;
            .worksheet-topic-container {
                padding-left: 12px;
            }
            .heading {
                color: $dark-grey;
                @include font-graphie(12,16,6);
            }
            
        }
        .grade-selector {
            color:#34389F
        }
        .worksheet-mobile-header{
            .mobile-header-left {
                color: #34389F;
                
            }
        }
    }
    .worksheets-nav .worksheet-mobile-header .mobile-header-center {
        color: #34389F;
        svg {
            @include svg-style(#34389F)
        }
    }
    .worksheets-nav .worksheet-mobile-header svg {
        @include svg-style( #34389F)
    }
    @media only screen and (max-width: $mobile-width) {
        .topic-selector {
            background-color: #EDEDF9;
            color: #1a1c6c;
            padding: 0;
           .level0 {
                background-color: rgba(52, 56, 159, 0.4);;
           }
           .level1 {
                background-color: $light-grey-1;
            }
            .level2 {
                background-color: $light-grey-2;
           }
        }
    }
}

@media only screen and (max-width: $mobile-width){

    .printable-worksheets{
        height: 100%;
        padding: 0 0 0 16px !important;
        overflow: hidden;
        overflow-y: auto;
        margin-bottom: 0;
    }

    .printable-worksheets__right {
        width: calc(100% + 16px);
        margin-left: -349px;
        padding: 0px;
    }
    .grade-selector {
        color: $white;
        .heading {
            @include font-graphie(16, 24, 7);
            display: none;
    
        }
        .grades-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            &:before {
                content: "Grade";
                margin-right: 24px;
                margin-top: 4px;
                @include font-graphie(14, 20, 4);
            }

            &.subject-selector{
                &:before {
                    content: "Subject";
                }
            }
    
            .grade {
                margin-right: 8px;
                height: 32px;
                padding: 0 12px;
            }
        }
    }
}