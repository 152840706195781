@import '../../../../baseStyles/styles.scss';

.worksheet-card {
    width: 187px;
    height: 196px;
    border-radius: 14px;
    background: linear-gradient(180deg, rgba(9, 10, 74, 0) 0%, #090A4A 100%);

    &.TEACHER_DASHBOARD{
        height: 317px;
        background: #FFFFFF;
        border: 1px solid #DCDCEA;
        overflow: hidden;
        width: 210px;
        .img-container {
            background-color: #FFFFFF;
            border-color: $light-purple-3;
            width: 210px;
            height: 130px;
            .bg-img-container {
                height: auto;
                img {
                    margin-top: -18px;
                }
            }
        }
        .info-container {
            background-color: #FFFFFF;
            padding: 16px;
            .details {
                .title{
                    font-family: 'Graphie';
                    color: $dark-grey;
                    width: 188px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    height: 40px;
                    -webkit-line-clamp: 2;
                    white-space: normal;
                    margin-bottom: 8px;
                }
                .meta_description {
                    font-family: 'Graphie';
                    color: $medium-grey-1;
                    width: 188px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    height: 54px;
                    -webkit-line-clamp: 3;
                }
            }
        }
    }

    .info-container {
        padding: 6px;
        box-sizing: border-box;

        .details {
            cursor: pointer;
        }

        .cta-container {
            margin-top: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .custom-download-btn{
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                width: 130px;
                background-color: $pure-purple;
                
                .icn-container {
                    box-sizing: border-box;
                    height: 16px;
                    width: 16px;
                }

                span {
                    display: inline-block;
                    margin-left: 8px;
                    @include font-graphie(16, 24, 6);
                }

                &:hover {
                    background-color: $navy-blue;
                }
            }

            .bookmark-button {
                background: #FFFFFF;
                border: 1px solid $light-grey-3;
                border-radius: 24px;
                width: 35px;
                height: 35px;
                padding: 4px 10px;
                color: #4F52C3;
                margin-right: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
    
                &:last-child {
                    margin-right: 0;
                }
    
                label {
                    display: none;
                    margin: 0;
                    color: var(--pure-purple);
                    @include font-graphie(16, 24, 6);
                    margin-left: 10px;
                }
    
                &:hover {
                    background: var(--light-grey-5);
                }
    
                svg {
                }
    
                &.active {
                    svg {
                        @include svg-style(var(--pure-purple));
                    }
                }
            }
        }
    }

    .img-container {
        cursor: pointer;
        width: 187px;
        height: 112px;
        border-radius: 10px;
        background: $pure-purple;
        border: 2px solid $pure-purple;
        overflow: hidden;
        box-sizing: border-box;
        position: relative;
        margin: 0 auto;
        .preview {
            display: none;
        }

        &:hover {
            .preview {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(180deg, rgba(9, 10, 74, 0) -37.2%, rgba(9, 10, 74, 0.7) 100%);
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;

                button {
                    background: transparent;
                    border: 1px solid var(--white);
                    border-radius: 16px;
                    color: var(--white);
                    @include font-graphie(16, 24, 6);
                    padding: 4px 12px;
                }
            }
        }
        .bg-img-container {
            border-radius: 16px;
            overflow: hidden;
            height: 108px;
            img {
                max-width: 110%;
                width: 104%;
                margin: -4px;
                margin-top: -58px;
            }
        }
        .actions {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 38px;
            padding: 0 8px;

            .download-button {
                svg {
                    zoom: 1;
                }
            }
        }
    }

    .title {
        font-family: 'Graphie';
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        color: #9D9FFC;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 177px;

        &.st-ws {
            color: #EDEDF9;
        }
    }

    .meta_description {
        font-family: 'Graphie';
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #EDEDF9;
        margin-bottom: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 177px;
        height: 48px;
        max-lines: 2;

        &.st-ws {
            margin-bottom: 0;
            font-weight: 600;
            height: 72px;
            -webkit-line-clamp: 3;
            max-lines: 3;
        }
    }
    .pdf-header {
        .title {
            width: fit-content;
        }
    }

}
@media only screen and (max-width: $tablet-width) {
    .worksheet-card {
        width: 186px;
        height: 183px;
        .img-container {
            height: 110px;
            width: 182px;

            .bg-img-container {
                height: 106px;
            }
        }
        .title {
            font-family: 'Graphie';
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            width: 174px;

        }
    
        .meta_description {
            font-family: 'Graphie';
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            width: 174px;
        }
    }  
}

@media only screen and (max-width: $mobile-width){

    .worksheet-card {
        width: 160px;
        height: 154px;
        .img-container {
            height: 84px;
            width: 156px;
            .bg-img-container {
                height: 80px;
            }
            .actions {
                .download-button {
                    svg {
                        zoom: 0.6;
                    }
                }
                .bookmark-button {
                    width: 24px;
                    height: 24px;
                    padding: 4px 4px;
                    svg {
                        zoom: 0.6;
                    }
                }
            }
        }
        
        .title {
            font-family: 'Graphie';
            font-weight: 700;
            font-size: 10px;
            line-height: 14px;
            width: 148px;   
        }
    
        .meta_description {
            font-family: 'Graphie';
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            width: 148px;
            color: #c8c9ff;
            overflow: hidden;
            text-overflow: ellipsis;
            max-height: 40px;
        }
    }  
}