// Fonts variables
$proxima-nova-base-font-family: 'proxima-nova', helvetica, arial, sans-serif;
$graphie-base-font-family: 'graphie', 'proxima-nova', helvetica, arial, sans-serif;
$fallback-font-family: helvetica, arial, sans-serif;
$fall-back-font-family : Helvetica, Arial, sans-serif;

// Typekit fonts variation
// proxima-nova: 300,400,600
// graphie: 400,500,600,700

// Google fonts variation
// Signika:400,300,600,700
// Roboto+Condensed:300,400
// Open+Sans:300,400,600,700,800
// Montserrat:400,700 # not in used.
// Khula:400,300,600,700
// Work+Sans:300,400,500,600


@function calc-lineheight($fontsize) {
    $tempfont: $fontsize;
    $lineheight: 0;

    @if($tempfont <=12) {
        $lineheight: ($tempfont + 4);
        @return $lineheight;
    }

    @else if($tempfont >=13 and $tempfont <=24) {
        $lineheight: ($tempfont + 6);
        @return $lineheight;
    }

    @else if($tempfont >=25 and $tempfont <=36) {
        $lineheight: ($tempfont + 8);
        @return $lineheight;
    }

    @else if($tempfont >=37 and $tempfont <=50) {
        $lineheight: ($tempfont + 10);
        @return $lineheight;
    }

    @else {
        $lineheight: ($tempfont + 12);
        @return $lineheight;
    }
}

@function calc-font-weight($fw-get, $fw-list, $fw-default) {
    @each $current-fw in $fw-list {
        @if ($current-fw ==$fw-get) {
            @return $current-fw;
        }
    }

    @return $fw-default;
}


@mixin font-proxima-nova($font-size: 1, $line-height: 0, $fw: 4) {
    $line-height-calc: 0;
    $fw-list: 3 4 6;
    $fw: calc-font-weight($fw, $fw-list, 4);

    @if($line-height ==0) {
        $line-height-calc: calc-lineheight($font-size);
    }

    @else {
        $line-height-calc: $line-height;
    }

    font-style: normal;
    font-weight: #{$fw * 100};
    font-size: #{$font-size + 0px};
    line-height: #{$line-height-calc + 0px};
    font-family: $proxima-nova-base-font-family;
}

@mixin font-graphie($font-size: 1, $line-height: 0, $fw: 6) {
    $line-height-calc: 0;
    $fw-list: 3 4 5 6 7;
    $fw: calc-font-weight($fw, $fw-list, 6);

    @if($line-height ==0) {
        $line-height-calc: calc-lineheight($font-size);
    }

    @else {
        $line-height-calc: $line-height;
    }

    font-style: normal;
    font-weight: #{$fw * 100};
    font-size: #{$font-size + 0px};
    line-height: #{$line-height-calc + 0px};
    font-family: $graphie-base-font-family;
}

@mixin font-graphie-book($font-size: 1, $line-height: 0) {
    @include font-graphie($font-size, $line-height, 4);
}

@mixin fontSize-lineHeight($font-size: 1, $line-height: 0, $fw: 0) {
    $line-height-calc: 0;

    @if($line-height ==0) {
        $line-height-calc: calc-lineheight($font-size);
    }

    @else {
        $line-height-calc: $line-height;
    }

    font-size: $font-size +0px;
    line-height: $line-height-calc +0px;

    @if($fw !=0) {
        font-weight: #{$fw * 100};
    }
}