@import '@baseStyles/styles.scss';

.app-pdf-viewer {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000000e3;
    z-index: 999;
    animation-name: pdf-viewer-animation;
    animation-duration: 0.25s;
    animation-timing-function: ease-in;

    @keyframes pdf-viewer-animation {
        from {top: 100%};
        to {top: 56px};
    }

    .react-pdf__Document {
        display: flex;
    }

    .pdf-header {
        display: flex;
        justify-content: space-between;
        // align-items: baseline;
        padding: 26px 38px;
        padding-bottom: 0;

        .header-info {
            display: flex;
            align-items: baseline;
            .back-button {
                cursor: pointer;
                margin-right: 32px;
                @include svg-style($white)
            }

            .details {
                // width: 80%;
                .title {
                    color: $white;
                    @include font-graphie(20, 24, 6);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 1;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    white-space: normal;
                }
                .subtitle {
                    margin-top: 8px;
                    color: $light-grey-5;
                    @include font-graphie(14, 18, 4);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    white-space: normal;
                }
            }
        }

        .actions {
            display: flex;
            align-items: center;
            margin-left: 36px;
            height: fit-content;

            .save-button {
                @include font-graphie(16, 24, 6);
                color: $white;
                margin-right: 16px;
                cursor: pointer;
                display: flex;
                align-items: center;
                svg {
                    margin-right: 8px;
                    @include svg-style($white);
                    zoom: 1.2;
                }
                &:hover {
                    color: $light-grey-5;
                    svg {
                        @include svg-style($light-grey-5);
                    }
                }
            }
            .download-button {
                @include font-graphie(16, 24, 6);
                color: $navy-blue;
                background-color: $white;
                border-radius: 24px;
                padding: 4px 16px;
                box-sizing: border-box;
                cursor: pointer;
                display: flex;
                align-items: center;
                svg {
                    margin-right: 8px;
                    @include svg-style($navy-blue);
                    zoom: 0.8;
                }
                &:hover {
                    background-color: $light-grey-5;
                }
            }
        }
    }

    .thumbnails {
        padding: 32px;
        overflow: auto;
        width: 256px;
        box-sizing: border-box;

        .thumbnail {
            width: 161px;
            margin-bottom: 43px;
            overflow: hidden;
            &.active {
                .page {
                    box-sizing: border-box;
                    border: 8px solid #BDBFF9;
                }

                .info {
                    margin-top: 8px;
                }
            }
        }

        .info {
            text-align: center;
            color: #fff;
            margin-top: 16px;
            @include font-graphie(16, 20, 6)
        }

        .react-pdf__Page__canvas {
            // width: 145px !important;
            // height: 204px !important;
        }
    }

    .preview {
        padding: 32px;
        width: 100%;
        display: flex;
        justify-content: center;
        overflow: auto;
        .react-pdf__Page__canvas {
            // width: 500px !important;
            height: 84vh !important;
        }
    }
}