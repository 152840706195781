@import '@baseStyles/styles.scss';

.auth-signin-view {
    background-color: #ffffff;
    overflow: auto;
    position: relative;

    .login-back {
        top: 61px;
        position: absolute;
        z-index: 100;
        left: 34px;
        cursor: pointer;

        i {
            color: #E6E7FD;
            font-size: 20px;
            @include transition(all 0.5s);
        }

        &.dark {
            i {
                color: #707070;
            }
        }

        &:hover {
            i {
                font-size: 24px;
            }
        }

        @media screen and (max-width: 1279px) {
            i {
                font-size: 18px;
            }
        }

        @media screen and (max-width: 834px) {
            top: 36px;
            left: 15px;

            i {
                font-size: 16px;
            }
        }
    }

    .login-view-selector {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: inherit;

        &>h4 {
            color: #464a4d;
            margin-bottom: 28px;
            margin-top: 100px;
            @include font-proxima-nova(30, 36, 6);
        }

        .login-tiles {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            .login-tile {
                border: 1px solid #d9d9d9;
                border-radius: 10px;
                background-color: $white;
                box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.23);
                width: 228px;
                height: 217px;
                margin: 30px 40px;
                @include transition(all 0.5s);

                &:hover {
                    border: 1px solid #bfbfbf;
                    box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.29);
                    cursor: pointer;
                }

                &>img {
                    width: 100%;
                    height: 150px;
                }

                &>h4 {
                    text-align: center;
                    margin: 0;
                    @include font-proxima-nova(30, 64, 6);
                    border-top: 1px solid #d9d9d9;
                }
            }
        }

        @media screen and (min-height: 700px) {
            justify-content: center;
    
            &>h4 {
                margin-top: 0px;
            }
        }
    }
}