@import '@baseStyles/styles.scss';

.student-login {
    background: #090A4A;
    height: 100%;
    position: relative;
    width: 100%;
    
    @media screen and (min-width: 1279px) {
        .remember-me {
            .login-types{
                .login-type {
                    img {
                        width: 200px;
                        height: 200px;
                    }
                }
            }
        }
    }

    .login-types {
        display: flex;
        margin-top: 8px;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 64px;

        .login-type {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 64px;
            @include transition(all .5s);
          

            & > img {
                box-shadow: 0px -6.6666669845581055px 0px 0px rgba(189, 191, 249, 0.25) inset;
                width: 184px;
                height: 184px;
                background-color: #4F52C3;
                border-radius: 30px;
                box-sizing: border-box;
                padding: 30px;
                @include transition(all .5s);
            }

            &:hover {
                &>img {
                    background-color: #34389F;
                    box-shadow: 0px -6.670000076293945px 0px 0px rgba(189, 191, 249, 0.20) inset;
                }
            }

            &:last-child {
                margin-right: 0;
            }

            label {
                margin: 0;
                margin-top: 16px;
                @include font-graphie(24, 32, 6);
                color: $white;
            }

            &:hover {
                transform: scale(1.05);
                cursor: pointer;
            }
         }

         @media screen and (max-width: 1279px) {
            .login-type {
                margin-right: 48px;
                img {
                    width: 176px;
                    height: 176px;
                    padding: 29px;
                    box-shadow: 0px -5.999999523162842px 0px 0px rgba(189, 191, 249, 0.25) inset;
                }

                label {
                    @include font-graphie(18, 24, 6);
                }
            }
        }

        @media screen and (max-width: 834px) {
            .login-type {
                margin-right: 32px;
                img {
                    width: 120px;
                    height: 120px;
                    box-shadow: 0px -4.499999523162842px 0px 0px rgba(189, 191, 249, 0.25) inset;
                    padding: 20px;
                }
                label {
                    margin-top: 12px;
                    @include font-graphie(18, 24, 6);
                }
            }
        }
    }

    .clever-login {
        @include font-graphie(18, 26, 6);
        margin-top: 48px;
        border-radius: 800px;
        background: #4274F6;
        box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.25) inset;
        padding: 16px 38px;
        color: #FFFFFF;
        cursor: pointer; 
        border: 0;
        width: 201px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        @include transition(all .5s);
        &:hover {
            background: #264AB6;
            box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.25) inset;
            transform: scale(1.05);
        }
        &.disabled {
            opacity: 0.4;
            pointer-events: none;
        }
        &:active {
            background: #264AB6;
            box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.25) inset !important;
        }

        svg {
            margin-right: 16px;
        }
    }

    @media screen and (max-width: 1279px) { 
        .clever-login {
            @include font-graphie(16, 24, 6);
            padding: 14px 32px;
            width: 179px;
            margin-top: 40px;
        }
    }

    @media screen and (max-width: 834px) {
        .clever-login {
            @include font-graphie(16, 20, 6);
            padding: 14px 28px;
            width: 169px;
            margin-top: 32px;
        }
    }
    .autocomplete-hide {
        height: 0;
        width: 0;
        opacity: 0;
        padding: 0;
        border: none;
        outline: 0;
    }

    .student-login-bg {
        height: inherit;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;

        img {
            display: block;
            height: inherit;
            z-index: 1;
            width: 100%;
        }
    }

    .student-login-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: inherit;
        z-index: 2;
        background-color: transparent;
        position: relative;
        overflow: auto;

        &.select-login {
            justify-content: flex-start !important;
            padding-top: 85px;
            box-sizing: border-box;

            @media screen and (max-width: 1279px) {
                padding-top: 40px;
            }

            @media screen and (max-width: 834px) {
                padding-top: 0px;
            }
        }

        &>h4 {
            @include font-graphie(32, 42, 7);
            color: #FFFFFF;
            margin-bottom: 40px;
            margin-top: 64px;
        }

        &>p {
            @include font-graphie(18, 28, 6);
            color: #BDBFF9;
            margin-top: 72px;
            letter-spacing: 0.36px;

            &>a {
                text-decoration: underline;
                color: #BDBFF9;

                &:hover {
                    cursor: pointer;
                    color: #FFFFFF;
                }
            }
        }

        &>img {
            margin-top: 37px;
            bottom: 0;
            z-index: 1;

            &:hover {
                cursor: pointer;
                transform: scale(1.1);
                transform-origin: center bottom;
                @include transition(all 0.5s);
            }
        }

        .info {
            @include font-graphie(18, 24, 6);
            text-align: center;
            margin-top: 16px;

            &>.loading {
                color: #D490F0;
            }

            &>.error {
                color: #FEB49E;
            }

            &>.success {
                color: #B7FAED;
            }
        }

        .info-capture-msg {
            @include font-graphie(14, 20, 6);
            letter-spacing: 0.28px;
            color: #fff;
            color: rgba(255, 255, 255, 0.80);
            max-width: 50%;
            text-align: center;
        }

        .klass-code-input {
            display: flex;

            .code-char {
                width: 104px;
                height: 88px;
                padding: 12px 24px;
                background: #090A4A;
                border: 3px solid #797CDB;
                border-radius: 70px;
                box-sizing: border-box;
                margin-right: 24px;
                @include font-graphie(56, 64, 6);
                text-align: center;
                text-transform: capitalize;
                @include transition(all 1s);

                &.filled,
                &:focus {
                    color: #D490F0;
                    border: 3px solid #B746E6;
                    background-color: #020533;
                }

                &.error {
                    color: #FEB49E;
                    border: 3px solid #FEB49E;
                }

                &.success {
                    color: #B7FAED;
                    border: 3px solid #B7FAED;
                }

                @media screen and (max-width: 1279px) {
                    width: 96px;
                    height: 76px;
                    @include font-graphie(56, 60, 6);
                    padding: 8px 20px;
                    margin-right: 16px;
                }

                @media screen and (max-width: 834px) {
                    width: 48px;
                    height: 40px;
                    @include font-graphie(24, 28, 6);
                    padding: 6px 0px;
                    margin-right: 8px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &:not(.qr-code) {
            @media screen and (min-height: 700px) {
                justify-content: center;

                &>p {
                    margin-bottom: 300px;
                }

                &>img {
                    position: absolute;
                }
            }
        }


        &.qr-code {
            @media screen and (min-height: 858px) {
                &>img {
                    position: absolute;
                }
            }
        }

        @media screen and (max-width: 1279px) {
            & > h4 {
                @include font-graphie(24, 32, 7);
                margin-top: 40px;
                margin-bottom: 32px;
            }

            & > p {
                @include font-graphie(18, 28, 6);
                margin-top: 56px;
            }
        }

        @media screen and (max-width: 834px) {
            & > h4 {
                @include font-graphie(20, 26, 7);
                margin-bottom: 24px;
            }

            & > p {
                @include font-graphie(14, 24, 6);
            }
        }
    }

    .remember-me-student-details {
        &>h4 {
            color: #FFFFFF;
            text-align: center;
            @include font-graphie(24, 32, 7);
            margin: 0;
            margin-top: 49px;
        }

        .student-card-l1 {
            background: linear-gradient(180deg, rgba(20, 25, 117, 0) 0%, #141975 100%);
            border-radius: 20px;
            height: 164px;
            width: 598px;
            margin-top: 48px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.08);
            cursor: pointer;

            &:hover {
                cursor: pointer;
                transform: scale(1.05);
                transform-origin: center;
                @include transition(all 0.5s);
                box-shadow: 1px 1px 24px rgba(0, 0, 0, 0.12);
            }

            @media screen and (max-width: 834px) {
                transform: scale(0.55);
    
                &:hover {
                    transform: scale(0.60);
                }

                h4 {
                    @include font-graphie(20, 32, 7);
                }
            }
        }

        .student-card-l2 {
            background: #4F52C3;
            border-radius: 18px;
            border: 2px solid #4F52C3;
            box-sizing: border-box;
            height: 156px;
            width: 590px;
        }

        .student-card {
            display: flex;
            height: 152px;
            width: 586px;
            border-radius: 24px;
            padding: 24px 32px 24px 24px;
            box-sizing: border-box;
            background: #BDBFF9;
            justify-content: space-between;
            align-items: center;
            @include transition(all 0.5s);

            &>.avatar {
                width: 100px;
            }

            &>.details {
                margin-left: 16px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: flex-start;

                label {
                    @include font-graphie(24, 32, 7);
                    color: #090A4A;
                    margin-bottom: 8px;
                }

                .badge {
                    background: rgba(121, 124, 219, 0.4);
                    border-radius: 6px;
                    padding: 4px 8px;
                    color: #141975;
                    @include font-graphie(18, 24, 7);
                }
            }

            &>.arrow {
                margin-left: auto;
                transform: rotate(180deg);
                width: 64px;
                height: 52px;
                vertical-align: middle;
                background: #4F52C3;
                border-radius: 27px;
                padding: 19px 38px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;

                i {
                    color: white;
                    font-size: 24px;
                }
            }
        }
    }

    .last-used-class {
        &>h4 {
            color: #FFFFFF;
            text-align: center;
            @include font-graphie(32, 42, 7);
            margin: 0;
            margin-top: 65px;
        }

        .classes-list {
            margin-top: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 24px;
        }

        .student-card-l1 {
            background: linear-gradient(180deg, rgba(20, 25, 117, 0) 0%, #141975 100%);
            border-radius: 20px;
            height: 92px;
            width: 420px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.08);
            cursor: pointer;

            &:hover {
                cursor: pointer;
                transform: scale(1.05);
                transform-origin: center;
                @include transition(all 0.5s);
                box-shadow: 1px 1px 24px rgba(0, 0, 0, 0.12);
            }
            &:active {
                transform: scale(0.95);
            }
        }

        .student-card-l2 {
            background: #4F52C3;
            border-radius: 18px;
            border: 2px solid #4F52C3;
            box-sizing: border-box;
            height: 84px;
            width: 412px;
        }

        .student-card {
            display: flex;
            height: 80px;
            width: 408px;
            border-radius: 20px;
            padding: 24px 32px;
            box-sizing: border-box;
            background: #BDBFF9;
            justify-content: space-between;
            align-items: center;
            @include transition(all 0.5s);

            label {
                @include font-graphie(24, 32, 6);
                color: #1A1A1A;
                margin: 0;
                cursor: pointer;
                max-width: 280px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            &>.arrow-icon {
                margin-left: auto;
                transform: rotate(180deg);
                width: 40px;
                height: 32px;
                vertical-align: middle;
                background: #4F52C3;
                border-radius: 27px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;

                i {
                    color: white;
                    font-size: 14px;
                }
            }
        }

        .or-sec {
            width: 651px;
            margin: 48px auto 0 auto;
            border-top: 1px solid #797CDB;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 0;
    
            .or-sec-text {
                position: relative;
                width: 32px;
                color: #797CDB;
                @include font-graphie(14, 20, 4);
                top: 3px;
                text-align: center;
            }
        }

        &>h5 {
            color: #FFFFFF;
            text-align: center;
            @include font-graphie(24, 32, 7);
            margin: 0;
            margin-top: 48px;
        }

        .login-btns {
            margin-top: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 24px;

            .login-btn-type {
                border-radius: 800px;
                background: #4F52C3;
                box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.25) inset;
                padding: 16px 38px;
                width: 200px;
                height: 58px;
                box-sizing: border-box;
                @include font-graphie(18, 26, 6);
                color: #EDEDF9;
                display: flex;
                align-items: center;
                justify-content: center;
                @include transition(all 0.5s);
                img {
                    width: 26px;
                    margin-right: 10px;
                }

                svg {
                    margin-right: 10px;
                }

                &:hover {
                    transform: scale(1.05);
                    background: #34389F;
                    box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.25) inset;
                }
                &.disabled {
                    opacity: 0.4;
                    pointer-events: none;
                }
                &:active {
                    background: #34389F;
                    box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.25) inset;
                }
            }

            .clever-login {
                margin-top: 0;
            }
        }
        @media screen and (max-width: 1279px) {
            &>h4 {
                @include font-graphie(24, 32, 7);
            }
            .student-card-l1 {
                height: 84px;
                width: 360px;
            }
            .student-card-l2 {
                height: 76px;
                width: 352px;
            }
            .student-card {
                padding: 20px 28px;
                width: 348px;
                height: 72px;
                label {
                    max-width: 228px;
                    @include font-graphie(20, 26, 6)
                }
            }
            .or-sec {
                margin-top: 40px;
                width: 585px;
                .or-sec-text {
                    @include font-graphie(16, 24, 4);
                }
            }
    
            &>h5 {
                @include font-graphie(20, 26, 7);
            }
            .login-btns {
                .login-btn-type {
                    width: 179px;
                    height: 52px;
                    padding: 14px 32px;
                    @include font-graphie(16, 24, 6);
                }
            }

         }
        @media screen and (max-width: 834px) { 
            &>h4 {
                @include font-graphie(20, 26, 7);
            }
            .classes-list {
                margin-top: 24px;
                flex-direction: column;
            }
            .student-card-l1 {
                height: 72px;
                width: 280px;
            }
            .student-card-l2 {
                height: 64px;
                width: 272px;
            }
            .student-card {
                padding: 18px 26px;
                width: 268px;
                height: 60px;
                label {
                    max-width: 162px;
                    @include font-graphie(18, 24, 6)
                }
            }
            .or-sec {
                margin-top: 32px;
                width: 72px;
                .or-sec-text {
                    @include font-graphie(14, 20, 4);
                }
            }
    
            &>h5 {
                @include font-graphie(18, 24, 7);
                margin-top: 32px;
            }
            .login-btns {
                flex-direction: column;
                gap: 16px;
                .login-btn-type {
                    width: 169px;
                    height: 48px;
                    padding: 14px 28px;
                    @include font-graphie(16, 20, 6);
                }
            }
        }

    }

    .or-sec {
        width: 186px;
        margin-top: 55px;
        border-top: 1px solid #797CDB;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0;

        .or-sec-text {
            position: relative;
            width: 32px;
            color: #BDBFF9;
            font-size: 16px;
            line-height: 20px;
            top: 3px;
            background: #090A4A;
            text-align: center;
        }
    }

    .allow-camera-access {
        margin-top: 32px;
        display: inline-flex;
        height: 58px;
        padding: 16px 38px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 32px;
        box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.20) inset;
        background: #EDEDF9;
        border: 0;
        color: #090A4A;
        letter-spacing: 0.36px;
        @include font-graphie(18, 26, 6);

        i {
            font-size: 28px;
        }
    }

    .qr-code-input {
        width: 480px;
        height: 320px;
        min-height: 320px;
        border-radius: 24px;
        overflow: hidden;
        background-color: #181B64;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        & > div {
            width: inherit;
            height: inherit;
        }

        .frame {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            overflow: hidden;
            
            path {
                stroke: #F9805D;
            }

            .scanner {
                width: 94%;
                height: 0;
                border-top: 2px solid #F9805D;
                opacity: 0.4000000059604645;
                animation: move 7s infinite;
                position: absolute;
                top: 5%;
                margin: 3%;

                @keyframes move {
                    0% {
                        top: 5%;
                    }

                    50% {
                        top: 90%;
                    }

                    100% {
                        top: 5%;
                    }
                }

                @media screen and (max-width: 1279px) {
                    width: 360px;
                    height: 240px;
                    min-height: 240px;
                }
        
                @media screen and (max-width: 834px) {
                    width: 270px;
                    height: 180px;
                    min-height: 180px;
                }
            }

            &.success {
                path {
                    stroke: #B7FAED;
                }

                .scanner {
                    border-top: 2px solid #B7FAED;
                }
            }
        }

        .qr-scanner-denied {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            img {
                width: 80%;
                height: auto;
            }

            &>p {
                margin: 0;
                display: flex;
                justify-content: center;
                margin-top: 22px;
                color: $pure-yellow;
                align-items: baseline;

                label {
                    color: $pure-yellow;
                    @include font-graphie(20, 26, 6);
                    margin-left: 8px;
                }
            }
        }

        .qr-reader {
            width: inherit;
            height: inherit;
            transform: scaleX(-1);

            &>div, video {
                width: inherit !important;
                height: inherit !important;
                padding: 0px !important;
                overflow: hidden !important;
                position: relative !important;
                border-radius: 24px !important;
                object-fit: cover !important;
            }
        }

        @media screen and (max-width: 1279px) {
            transform: scale(0.75);
            margin: -24px;
        }

        @media screen and (max-width: 834px) {
            transform: scale(0.56);
            margin: -60px;
        }
    }
}

.student-username-login {
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;

    &>h4 {
        @include font-graphie(28, 36, 7);
        color: #464A4D;
        text-align: center;
        margin-bottom: 22px;
        margin-top: 77px;
    }

    @media screen and (min-height: 700px) {
        justify-content: center;

        &>h4 {
            margin-top: 0px;
        }
    }
}