@import '@baseStyles/styles.scss';

.ws-teacher-header{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;

    &__left {
        display: flex;
        align-items: center;

        .ws-numbered-tag{
            box-sizing: border-box;
            height: 30px;
            display: flex;
            align-items: center;
            padding: 3px 6px 3px 4px;
            border-radius: 8px;
            cursor: pointer;
            transition: all .25s;

            &__icon {
                display: flex;
                align-items: center;
            }

            &__label {
                @include font-graphie(14, 21, 4);
                color: $dark-grey;
                margin-left: 4px;
            }

            &__number {
                margin-left: 4px;
                box-sizing: border-box;
                height: 20px;
                padding: 1px 6px;
                background-color: $light-grey-5;
                border-radius: 6px;
                @include font-graphie(12, 18, 6);
                color: $medium-grey-1;
            }

            &:hover{
                background-color: $light-purple-3;
                .ws-numbered-tag__icon{
                    svg {
                        @include svg-style($navy-blue);
                    }
                }
                .ws-numbered-tag__label{
                    color: $navy-blue;
                }
                .ws-numbered-tag__number{
                    color: $navy-blue;
                    background-color: $light-purple-1;
                }
            }

            &.left-margin {
                margin-left: 24px;
            }
        }
    }

    &__right {
        box-sizing: border-box;
        width: 320px;
        margin-left: auto;
        transition: none;
        height: 30px;

        &.increase-width{
            width: 100%;
            margin-left: auto;
            transition: width .5s ease-in-out;
        }

        .search-container {
            height: 30px;
            position: relative;
            #input-search {
                box-sizing: border-box;
                width: 320px;
                height: 30px;
                background: $white;
                border: 1px solid $light-grey-2;
                border-radius: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                @include font-graphie(16, 24, 7);
                color: $dark-grey;
                text-overflow: ellipsis;
                caret-color: #7678DF;
                padding-left: 36px;

                &::placeholder {
                    @include font-graphie(14, 20, 4);
                    color: $medium-grey-1;
                }
                &.increase-width {
                    width: 100%;
                    justify-content: space-between;
                    border-color: $medium-purple;
                    padding-right: 36px;
                    border: 1px solid $dull-purple;
                    &::placeholder {
                        padding-left: 0;
                    }
                }
                svg {
                    @include svg-style(#1A1C6C)
                }
            }
            .ws-search-icon {
                position: absolute;
                top: 6px;
                left: 12px;
                svg {
                    @include svg-style($medium-grey-2);
                }
            }
            .ws-cross-icon {
                position: absolute;
                right: 14px;
                top: 5px;
                cursor: pointer;
                svg {
                    @include svg-style($medium-grey-2);
                }
            }
        }
        .search-icon-container{
            position: absolute;
            right: 16px;
            top: 0px;
        }
        .search-dropdown {
            box-sizing: border-box;
            position: absolute;
            overflow-y: auto;
            background: #FFFFFF;
            border: 1px solid #DCDCEA;
            border-radius: 8px;
            top: 62px;
            left: 22px;
            min-height: 70px;
            max-height: 175px;
            z-index: 3;
            padding: 16px 0;
            width: calc(100% - 48px);

            li {
                padding: 8px 16px;
            }
            .search-result-type {
                @include font-graphie(13, 16, 4);
                color: $medium-grey-2;
                margin-bottom: 8px;
                margin-left: 16px;
            }
            .search-result {
                list-style: none;
                
                .title {
                    color: $dark-grey;
                    @include font-graphie(14, 20, 6);
                }
                .tree-name {
                    @include font-graphie(13, 16, 4);
                    color: $medium-grey-1;
                    margin-top: 4px;
                }
                .highlight-class {
                    color: $navy-blue;
                    background: inherit;
                }
                &:hover{
                    background-color: $light-grey-5;
                    cursor: pointer;
                    .title {
                        color: $navy-blue;
                    }
                }

            }
            .no-search-result {
                padding: 8px 24px;
                text-align: center;
                .heading {
                    @include font-graphie(14, 20, 7);
                    color: $dark-grey;
                    margin-bottom: 4px;
                }
                .body {
                    @include font-graphie(13, 16, 4);
                    color: $medium-grey-1;
                    width: 288px;
                    margin: 0 auto;
                }
            }
        }
    }
}

.worksheets-view {
    box-sizing: border-box;
    color: $white;
    padding-bottom: 22px;

    &__header {
        box-sizing: border-box;
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: #090a4a;
        padding-bottom: 12px;
        .ws-new-header {
            box-sizing: border-box;
            display: flex;
            align-items: flex-end;
            // justify-content: space-between;
            position: relative;

            &__active-topic {
                width: calc(100% - 211px);
                height: 52px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                .breadcrumbs{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;
                    @include font-graphie(14, 20, 4);
                    color: #9D9FFC;;

                    &__item {
                        letter-spacing: 0.02em;
                        height: 20px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        &:hover{
                            text-decoration: underline;
                            cursor: pointer; 
                        }
                    }
                }
                
                .topic {
                    @include font-graphie(20, 26, 6);
                    height: 26px;
                    width: calc(100% - 16px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &.mobile-header {
                    margin-left: 20px;

                    .breadcrumbs{
                        @include font-graphie(10, 14, 4);
                    }

                    .topic{
                        @include font-graphie(16, 20, 6);
                    }
                }
            }

            &__searchbox {
                box-sizing: border-box;
                width: 195px;
                margin-left: auto;
                transition: none;
                height: 30px;

                &.increase-width{
                    width: 100%;
                    margin-left: auto;
                    transition: width .5s ease-in-out;
                    margin-top: 22px;
                }

                .search-container {
                    position: relative;
                    #input-search {
                        box-sizing: border-box;
                        width: 195px;
                        height: 30px;
                        background: #1A1C6C;
                        border: 1px solid $medium-purple;
                        border-radius: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include font-graphie(16, 24, 4);
                        color: $white;
                        text-overflow: ellipsis;
                        caret-color: $white;
                        padding-left: 36px;

                        &::placeholder {
                            @include font-graphie(14, 20, 4);
                            color: $dull-purple;
                        }
                        &.increase-width {
                            width: 100%;
                            justify-content: space-between;
                            border-color: $medium-purple;
                            padding-right: 36px;
                            &::placeholder {
                                padding-left: 0;
                            }
                        }
                    }
                    .ws-search-icon {
                        position: absolute;
                        top: 6px;
                        left: 12px;
                        svg {
                            path {
                                fill: $white;
                              }
                            
                              rect {
                                fill: $white;
                              }
                        }
                    }
                    .ws-cross-icon {
                        position: absolute;
                        right: 14px;
                        top: 5px;
                        cursor: pointer;
                        svg {
                            path {
                                fill: $white;
                              }
                            
                              rect {
                                fill: $white;
                              }
                        }
                    }
                }
                .search-icon-container{
                    position: absolute;
                    right: 16px;
                    top: 0px;
                }
                .search-dropdown {
                    box-sizing: border-box;
                    position: absolute;
                    overflow-y: auto;
                    background: $white;
                    border: 1px solid $light-grey-2;
                    border-radius: 8px;
                    top: 58px;
                    left: 0;
                    width: 100%;
                    min-height: 70px;
                    height: min-content;
                    z-index: 3;
                    padding: 16px 0;
                    transition: all 2s ease-in-out;
                    li {
                        padding: 8px 16px;
                    }
                    .search-result-type {
                        @include font-graphie(13, 16, 4);
                        color: $medium-grey-2;
                        margin-bottom: 8px;
                        margin-left: 16px;
                    }
                    .search-result {
                        list-style: none;
                        
                        .title {
                            color: $dark-grey;
                            @include font-graphie(14, 20, 6);
                        }
                        .tree-name {
                            @include font-graphie(13, 16, 4);
                            color: $medium-grey-1;
                            margin-top: 4px;
                        }
                        .highlight-class {
                            color: $navy-blue;
                            background: inherit;
                        }
                        &:hover{
                            background-color: $light-grey-5;
                            cursor: pointer;
                            .title {
                                color: $navy-blue;
                            }
                        }
        
                    }
                    .no-search-result {
                        padding: 8px 24px;
                        text-align: center;
                        .heading {
                            @include font-graphie(14, 20, 7);
                            color: $dark-grey;
                            margin-bottom: 4px;
                        }
                        .body {
                            @include font-graphie(13, 16, 4);
                            color: $medium-grey-1;
                            width: 288px;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }

    &__list {
        height: 100%;
        .ws-rails-container{
            max-height: inherit;
            overflow-y: scroll;
            margin: 0;
            padding: 0;
            overflow-x: hidden;
            overflow-y: auto;

            .ws-rail{
                box-sizing: border-box;
                width: 100%;
                overflow: hidden;
                margin-bottom: 20px;

                &__header {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    margin-bottom: 12px;
                    position: sticky;
                    left: 20px;
                

                    .title {
                        @include font-graphie(18, 24, 6);
                        color: #ffffff;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .view-printables {
                        @include font-graphie(14, 20, 4);
                        color: #ffffff;
                        border-bottom: 2px solid #7678DF;
                        cursor: pointer;
                        min-width: max-content;
                    }
                }

                &__list {
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 16px 20px;
                    border-radius: 28px;
                    height: 223px;
                    background: rgba(52, 56, 159, 0.4);

                    .discover-more-container{
                        cursor: pointer;
                        height: min-content;
                        position: relative;
                        padding-right: 16px;
                        margin-top: -20px;

                        .playbles-count{
                            position: absolute;
                            top: 1px;
                            right: 20px;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 17px;
                            font-family: "graphie", "proxima-nova", helvetica, arial, sans-serif;
                            color: #141975;
                            width: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 20px;
                        }
                    }

                    &.student-rail{
                        height: 228px;

                        .worksheet-card {
                            margin-right: 20px;
                        }

                        @media screen and (max-width: $tablet-width) {
                            column-gap: 12px;
                            padding: 16px;
                            height: 220px;
                        }
                    }
                }

                &.ws-rail-mobile-view {
                    overflow-x: auto;
                    .ws-rail {

                        &__header {
                            margin: 0 20px -11px 20px;
                            .title {
                                @include font-graphie(14, 20, 7);
                            }
                        }

                        &__list {
                            flex-wrap: nowrap;
                            background: none;
                        }
                    }
                }
            }

            .all-ws {
                display: flex;
                flex-direction: column;

                &__title {
                    @include font-graphie(18, 24, 6);
                    color: $white;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &__list {
                    padding: 16px;
                }
            }

            &.ws-rails-container-mobile-view {
                overflow-x: auto;
            }
        }

        &.un-focus {
            opacity: 0.4;
        }
    }
}

.search-dropdown-fallback.search-dropdown-fallback.search-dropdown-fallback {
    top: 62px;
    left: 0;
    width: 100%;
}

@media screen and (max-width: $mobile-width) {

    .worksheets-view {
        padding: 0;
    }

    .worksheets-view__header {
        position: relative;
        padding-bottom: 0;
        width: 100%;
        .ws-new-header__active-topic{
            .breadcrumbs{
                align-items: flex-start;
            }
        }
        .ws-new-header__searchbox {
            .search-container {
                position: absolute;
                width: -webkit-fill-available;
                background-color: #34389F;
                padding: 16px;
                z-index: 999;
                margin-left: -2px;
                margin-top: -22px;
                .ws-search-icon {
                    top: 22px;
                    left: 31px;
                }
                .ws-cross-icon {
                    top: 22px;
                    right: 24px;
                }

            }

            .search-dropdown {
                padding: 16px 0;
                z-index: 9999;
                margin-top: -8px;
                margin-left: 22px;
                width: calc(100% - 48px);
            }
        }
    }

    .worksheets-view__list {
        height: 100%;
        .ws-rails-container.mobile-view {

            &:last-child {
                margin-bottom: 0;
            }

            .ws-rail{
                margin-bottom: 24px;
                overflow: hidden;
                overflow-x: auto;

                &__list {
                    height: 164px;
                }
            }
        }
    }
    
}