@import '@baseStyles/styles.scss';

.worksheets-nav {
    box-sizing: border-box;
    background: #1a1c6c;
    height: calc(100% - 42px);
    width: 332px;
    border-radius: 16px;
    padding: 0 30px;
    overflow-y: auto;
    @include custom-scrollbar;
    &:hover {
        padding-right: 22px;
    }

    &__grade-selector {
        top: 0;
        z-index: 2;
        padding-top: 20px;
        padding-bottom: 35px;
        background-color: #1a1c6c;
    }

    &__subject-selector {
        top: 0;
        z-index: 2;
        padding-top: 20px;
        padding-bottom: 35px;
    }
    &__topic-selector {
        padding-bottom: 20px;
    }
}

.grade-selector {
    color: $white;
    .heading {
        @include font-graphie(16, 24, 7);

    }
    .grades-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .grade {
            box-sizing: border-box;
            border: 1px solid white;
            height: 40px !important;
            border-radius: 30px;
            padding: 10px 16px !important;
            margin-right: 10px;
            margin-top: 8px;
            @include font-graphie(14, 20, 4);
            cursor: pointer;

            &:hover {
                background: rgba(52, 56, 159, 0.5);
            }
            &:last-child {
                margin-right: 0;
            }
        }
        .selected {
            background: rgba(52, 56, 159, 0.8);
            border: 1.5px solid #69D5C1;
            color: #69D5C1;
            pointer-events: none;
        }
    }
}

.topic-selector{
    color: $white;
    .heading {
        @include font-graphie(16, 24, 7);

    }
    .worksheet-subject-name {
        cursor: pointer;
        position: relative;
        min-height: 28px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        flex-direction: row;
        padding: 4px 2px;
        gap: 8px;
        @include font-graphie(14, 20, 7);
        .opened-caret {
            right: -2px;
            position: absolute;
            transform: rotate(180deg);
        }
        .caret-icon-container {
            right: -6px;
            position: absolute;
        }
        .worksheet-label {
            display: flex;
            align-items: center;
            flex: 1;
        }
        .marker {
            width: 3.09px;
            height: 16px;
            background: #69D5C1;
            margin-right: 8px;
        }
        svg {
            margin-right: 4px;
        }
    }
        .worksheet-topic-container {
            padding-left: 24px;
            cursor: pointer;
            .worksheet-topic-name {
                min-height: 28px;
                display: flex;
                align-items: center;
                border-radius: 4px;
                justify-content: space-between;
                flex-direction: row;
                padding: 4px 2px;
                gap: 8px;
                width: 100%;
                @include font-graphie(14, 20, 4);
                .worksheet-label {
                    display: flex;
                    align-items: center;
                    flex: 1;
                }
                .marker {
                    width: 3.09px;
                    height: 16px;
                    background: #69D5C1;
                    margin-right: 8px;
                }
                .opened-caret {
                    box-sizing: border-box;
                    transform: rotate(180deg);
                    width: 20.59px;
                    height: 20px;
                    border-radius: 10px;
                    background: rgba(79, 82, 195, 0.4);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: -4px;
                    &.highlighted-caret{
                        svg {
                            @include svg-style(#69D5C1);
                        }
                    }
                }
            }
            .selected {
                background: #34389F;

                &:hover {
                    background: rgba(52, 56, 159, 0.5);
                }
            }
            
            .worksheet-topic-children {
            }
        }
        
}
@media only screen and (max-width: $tablet-width) {
     
}

@media only screen and (max-width: $mobile-width){
   .worksheets-nav {
    position: fixed;
    top: 0;
    background-color: #34389F;
    z-index: 99999;
    width: 100%;
    visibility: hidden;
    left: 0;

    &__grade-selector{
        margin-top: 20px;
    }

    &__grade-selector, &__subject-selector, &__topic-selector{
        position: relative;
        padding: 0;
        padding-bottom: 12px;
        background: none;
        .grade-selector>.grades-container{
            background: rgba(52, 56, 159, 0.4);
        }
    }
    .worksheet-mobile-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        .mobile-header-left {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #EDEDF9;
            @include font-graphie(16, 20, 6);
            svg {
                margin-right: 8px;
            }
        }
        .mobile-header-center {
            color: #A5F9E8;
            display: flex;
            justify-content: center;
            align-items: center;
            @include font-graphie(12, 18, 4);
            .circle {
                height: 3.2px;
                width: 3.2px;
                border-radius: 1.6px;
                margin-left: 6px;
                margin-right: 6px;
                background-color: #A5F9E8;
            }

        }
    }
   }
   .show-on-mobile {
        visibility: visible;
        background: #090A4A;
        padding: 16px;
        height: 100%;
    }
   .worksheet-mobile-options {
        visibility: visible;
        position: fixed;
        z-index: 999;
        display: flex;
        gap: 8px;  
        right: 16px; 
   }
   .topic-selector {
        box-sizing: border-box;
        max-height: 400px;
        background-color: #1a1c6c;
        border-radius: 16px;
        padding: 16px;
        position: relative;

        .levels-container.levels-container.levels-container {
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            border-radius: 16px;
            overflow: hidden;
            margin-top: 10.5px;
            border-radius: 16px;
            max-height: 300px;
        }

        .levels-cta-container{
            position: absolute;
            display: flex;
            justify-content: center;
            width: calc(100% - 44px);
            column-gap: 12px;
            flex-wrap: wrap;
            row-gap: 12px;

            .custom-reset-btn{
                box-sizing: border-box;
                min-width: 210px;
                border: 1px solid #EDEDF9;
            }

            .custom-apply-btn{
                box-sizing: border-box;
                min-width: 210px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .worksheet-topic-container {
            box-sizing: border-box;
            padding-left: 0;
            padding: 12px;
            cursor: pointer;
            &.selected {
                border: 2px solid #69D5C1;
                border-radius: 16px;
                color: #A5F9E8;
                padding: 10px;
                background-color: #2f3295;
            }
            svg {
                transform: rotate(-90deg);
            }
        }
        .worksheet-subject-name { 
            flex-direction: column;
            align-items: flex-start;
        }
        .worksheet-subject-name {
            display: flex;
            flex: 1;
            height: 100%;
        }
        .worksheet-topic-name {
            
        }
        .mobile-list {
            flex: 1
        }
        .level0 {
            background-color:#1d2073;
            max-height: 300px;
            overflow: auto;
            @include custom-scrollbar;
        }
        .level1 {
            background-color:#2f3295;
            max-height: 300px;
            height: 300px;
            overflow: auto;
            @include custom-scrollbar;
        }
        .level2 {
            background-color: #4446b2;
            max-height: 300px;
            height: 300px;
            overflow: auto;
            @include custom-scrollbar;
        }
   }
   .grades-container {
        background-color: #34389F;
        border-radius: 16px;
        padding: 16px;
   }
   .worksheets-teacher {
       background-color: #EDEDF9;
       color: #34389F;

       .grades-container {
           background-color: #EDEDF9;
           color: #34389F;
           border-radius: 16px;
           padding: 16px;
       }
   }
}