@import '@baseStyles/styles.scss';

.base-button {
  @include font-graphie(16, 24);
  user-select: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  padding: 8px 20px;
  margin: 0;
  text-align: center;
  text-decoration: none;
  background-color: $primary-cta-fill-color;
  transition: .15s;
  &:disabled,
  &.disable {
    &:hover,
    &:active,
    &:focus {
      border: 1px solid $primary-cta-fill-color;
      margin-top: 0;
    }
  }
}

.primary-cta-button {
  @extend .base-button;
  background-color: $primary-cta-fill-color;
  border-radius: 50px;
  color: $primary-cta-text-color;
  outline: none;
  a {
    color: $primary-cta-text-color;
  }
  &:hover, &:active {
    background-color: $primary-cta-fill-hover-color;
    border-color: $primary-cta-fill-hover-color;
    color: $primary-cta-text-hover-color ;
    // margin: 0;
    outline: 0;
  }
}

.default-cta-button {
  @extend .base-button;
  background-color: $default-cta-fill-color;
  border-radius: 50px;
  color: $default-cta-text-color;
  outline: none;
  a {
    color: $default-cta-text-color;
  }
  &:hover, &:active {
    background-color: $default-cta-fill-hover-color;
    border-color: $default-cta-fill-hover-color;
    color: $default-cta-text-hover-color ;
    // margin: 0;
    outline: 0;
  }
}

.secondary-cta-button {
  @extend .base-button;
  background-color: $secondary-cta-fill-color;
  box-shadow: 0 0 0 1px $secondary-cta-border-color inset;
  border-radius: 50px;
  color: $secondary-cta-text-color;
  outline: none;
  a {
    color: $secondary-cta-text-color;
  }
  &.disabled {
    color: $secondary-cta-text-color;
  }
  &:hover, &:active {
    background-color: $secondary-cta-fill-hover-color;
    box-shadow: 0 0 0 1px $secondary-cta-border-hover-color inset;
    //border-color: $secondary-cta-border-hover-color;
    color: $secondary-cta-text-hover-color ;
    // margin: 0;
    outline: 0;
  }
}

.button-star-container {
  position: absolute;
  top: 2px;
  z-index: 2;
  right: 2px;
}

.sync-cta-button {
  @extend .base-button;
  background-color: $sync-cta-fill-color;
  border: 1px solid $sync-cta-border-color;
  border-radius: 50px;
  color: $sync-cta-text-color;
  outline: none;
  a {
    color: $sync-cta-text-color;
  }
  &:hover, &:active {
    background-color: $sync-cta-fill-hover-color;
    border-color: $sync-cta-border-hover-color;
    color: $sync-cta-text-hover-color ;
    // margin: 0;
    outline: 0;
    i {
      color: $sync-cta-text-hover-color ;
    }
  }
}

.disabled {
  pointer-events: none;
}