.global {
  --dark-grey       : #1A1A1A;
  --dark-grey-2     : #575757;
  --medium-grey-1   : #6C6C73;
  --medium-grey-2   : #8F8F9B;
  --light-grey-1    : #CACAD8;
  --light-gray-1    : #D6D6D6;
  --light-grey-2    : #DCDCEA;
  --light-gray-2    : #EDEDED;
  --light-gray-3    : #EEEFF3;
  --light-grey-3    : #EDEDF9;
  --light-grey-4    : #F8F8FD;
  --light-grey-5    : #E7E7F2;
  --white           : #FFFFFF;

  --dark-navy-blue  : #090A4A;
  --navy-blue       : #141975;
  --gray-navy-blue  : #2B2C63;
  --gray-navy-blue-2   : #494A88;


  --pure-blue       : #2284E2;
  --light-blue-1    : #A7CEF3;
  --light-blue-2    : #D3E6F9;
  --light-blue-3    : #EDF5FD;
  --light-blue-4    : #EAF4FF;

  --pure-purple     : #4F52C3;
  --dark-purple     : #38397B;
  --medium-purple   : #797CDB;
  --dull-purple     : #989BEC;
  --purple          : #E6E7FD;
  --light-purple-1  : #BDBFF9;
  --light-purple-2  : #DEDFFC;
  --light-purple-3  : #E6E7FD;

  --magenta-3       : #8535A9;
  --magenta-4       : #B746E6;
  --magenta-8       : #F1DAFA;

  --mint-green      : #537D74;
  --dark-green      : #4CAB78;
  --pure-green      : #69D59B;
  --light-green     : #F3FCF7;
  --light-green-1   : #83C034;
  --light-green-2   : #68a526;
  --dark-green-1    : #537d74;

  --dark-yellow     : #D7A22B;
  --pure-yellow     : #FFC543;
  --light-yellow-1  : #FFE8B4;
  --light-yellow-2  : #FFF3D9;
  --light-yellow-3  : #FFFAF0;

  --dark-orange     : #C55314;
  --pure-orange     : #EE7532;
  --light-orange    : #FFF5F2;

  --dark-red        : #D23446;
  --pure-red        : #F1465A;
  --light-red       : #FDF1F1;
  --dark-red-2      : #B84040;

  --pure-black      : #000000;

}

