@import '@baseStyles/styles.scss';

.all-worksheets{
    height: inherit;
    width: 100%;
    position: relative;
    overflow-y: auto;
    position: relative;

    &__header {
        padding-top: 8px;
        padding-bottom: 16px;
        display: flex;
        column-gap: 16px;
        align-items: center;
        position: sticky;
        top: 0px;
        left: 0px;
        background-color: #090a4a;
        z-index: 1;

        .go-back{
            color: $white;
            display: flex;
            align-items: center;
            cursor: pointer;

            &.white-icon {
                svg {
                    path {
                        fill: #FFFFFF;
                      }
                    
                      rect {
                        fill: #FFFFFF;
                      }
                }
            }
        }

        .title {
            @include font-graphie(18, 22, 6);
            color: $white;
            max-width: 90%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .ws-title-prefix {
                @include font-graphie(16, 22, 7);
            }
        }
    }

    &__loading-container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__list {
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-bottom: 24px;
        gap: 21px;

        .worksheet-card {
            &.STUDENT_DASHBOARD {
                margin-right: 20px;
                margin-bottom: 20px;
            }
        }

        .empty-container {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .empty-search-worksheets-body {
                text-align: center;
                padding-top: 80px;
                // need to change
                padding-bottom: 200px;
            
                .main-text {
                    @include font-graphie(20, 28, 7);
                    color: $dark-grey;
                    margin-top: 8px;
                }
            
                p {
                    margin-top: 8px;
                    color: $medium-grey-1;
                    @include font-graphie(16, 20, 4);
                }
                .align-centre {
                    width: 337px;
                    margin: 8px auto 0 auto;
                }
            
                .fixed-width {
                    width: 392px;
                    margin: 8px auto 24px auto;
                }
            }

            &.white-theme {
                .empty-search-worksheets-body {
                    .main-text, p {
                        color: $white;
                    }
                }
            }
        }
    }
}

.saved-worksheets{
    height: inherit;
    width: 100%;
    position: relative;
    overflow-y: auto;
    position: relative;

    &__header {
        padding-top: 8px;
        padding-bottom: 16px;
        display: flex;
        column-gap: 16px;
        align-items: center;
        position: sticky;
        top: 0px;
        left: 0px;
        background-color: #090a4a;
        z-index: 1;

        .go-back{
            color: $white;
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        .title {
            @include font-graphie(18, 22, 6);
            color: $white;
            max-width: 90%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__loading-container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__list {
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        row-gap: 24px;
        justify-content: flex-start;
        column-gap: 18px;
        padding-bottom: 24px;

        .empty-container{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.downloaded-worksheets{
    height: inherit;
    width: 100%;
    position: relative;
    overflow-y: auto;
    position: relative;

    &__header {
        padding-top: 8px;
        padding-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 0px;
        left: 0px;
        background-color: #090a4a;
        z-index: 1;

        .left {
            display: flex;
            column-gap: 16px;

            .go-back{
                color: $white;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
    
            .title {
                @include font-graphie(18, 22, 6);
                color: $white;
                max-width: 90%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .right {
            margin-left: auto;
            display: flex;
            align-items: center;
            column-gap: 32px;

            input {
                margin-right: 8px;
                cursor: pointer;
            }

            .change-days {
                @include font-graphie(14, 16, 4);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: default;
                color: $medium-grey-1;
                &.highlight{
                    color: $dark-grey;
                }
            }
        }
    }

    &__loading-container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__list {
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        row-gap: 24px;
        justify-content: flex-start;
        column-gap: 18px;
        padding-bottom: 24px;

        .empty-container{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.ws-loader-container {
    height: 100px;
    width: 100px;
    margin: 50px auto;
}

@media only screen and (max-width: $mobile-width){
    .all-worksheets, .saved-worksheets, .downloaded-worksheets {
        top: -1px;
        &__header {
            column-gap: 8px;

            .go-back {
                svg {
                    path {
                        fill: #FFFFFF;
                      }
                    
                      rect {
                        fill: #FFFFFF;
                      }
                }
            }
        }

        &__list {
            column-gap: 12px;
        }
    }
}