@import '@baseStyles/styles.scss';

.qr-code-popup {
  .qr-code-overlay {
    background: rgba(0,0,0,0.75);
    cursor: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
  }

  .qr-code-popup {
    padding: 32px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1050;
    width: 534px;
    box-sizing: border-box;
    border-radius: 16px;
    background: #34389F;
    transform: translate(-50%, -50%);
    text-align: center;
    * {
      margin: 0;
    }

    .cross-icon {
      position: absolute;
      top: 3px;
      right: -44px;
      color: #FFFFFF;
      background-color: #34389F;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: none;
      font-size: 16px;
    }

    .sound-icon {
      position: absolute;
      top: 32px;
      left: 32px;
      cursor: pointer;
    }

    h4 {
      @include font-graphie(24, 32, 7);
      color: #FFF;
      margin-bottom: 8px;
    }

    #qr-err-animation {
      margin: 19.5px auto;
      width: 293px;
    }

    p {
      color: #CACAD8;
      @include font-graphie(14, 20, 4);
      width: 250px;
      margin: 0 auto;
    }

    .qr-code-btns {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
      button {
        @include font-graphie(18, 26, 6);
        border-radius: 32px;
        background-color: #EDEDF9;
        box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.20) inset;
        padding: 11px 28px;
        color: #090A4A;
        cursor: pointer; 
        border: 0;
        width: 223px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background: #BDBFF9;
        }
        &:focus {
          background: #BDBFF9 !important;
          box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.15) inset !important;
        }
        
        &.class-code {
          background-color: transparent;
          color: #EDEDF9;
          border: 2px solid #7678DF;
          box-shadow: none;
          &:hover {
            background: rgba(118, 120, 223, 0.20);
          }
          &:focus {
            border: 2px solid #EDEDF9 !important;
            background: rgba(118, 120, 223, 0.20) !important;
          }
        }
      }
    }
    @media screen and (max-width: 1279px) {
      h4 {
        @include font-graphie(20, 26, 7);
      }
      p {
        @include font-graphie(12, 18, 4);
      }
      .qr-code-btns { 
        button {
          width: 204px;
          padding: 12px 28px;
          @include font-graphie(16, 24, 6);
        }
      }
    }
  
    @media screen and (max-width: 834px) {
      padding: 24px;
      max-width: 280px;
      .sound-icon {
        top: 16px; // 8px less due to scaling
        left: 16px;
        transform: scale(0.6); 
      }
      .cross-icon {
        top: -38px;
        right: 0px;
      }
      h4 {
        @include font-graphie(18, 24, 7);
        max-width: 168px;
        margin: 0 auto 8px auto;
      }
      p {
        @include font-graphie(10, 14, 4);
        max-width: 168px;
      }
      #qr-err-animation {
        width: 232px;
      }
      .qr-code-btns { 
        flex-direction: column-reverse;
        gap: 16px;
        button {
          width: 151px;
          padding: 10px 20px;
          @include font-graphie(12, 18, 6);
        }
      }
    }
  }
}